import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { setCookie } from "nookies";
import * as React from "react";
import { useForm } from "react-hook-form";
import {
  JudgeAuthorizationMutationVariables,
  MeDocument,
  MeQuery,
  useJudgeAuthorizationMutation,
} from "../../generated/graphql";
import { PageMeComp, ssrMe } from "../../generated/page";
import { Label, Input, Button } from '@windmill/react-ui';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { props } = await ssrMe.getServerPage({}, ctx, true);
  return { props };
};

const SignIn: PageMeComp = () => {
  const router = useRouter();

  const getIPFromAmazon = (variables) => {
    fetch("https://checkip.amazonaws.com/")
      .then((res) => res.text())
      .then((data) => {
        signIn({
          variables: {
            ...variables,
            ipAddress: data,
          },
        });
      })
      .catch((e) => alert(e));
  };

  const { register, handleSubmit, errors, setError } =
    useForm<JudgeAuthorizationMutationVariables>({
      defaultValues: {
        judgeId: "",
        password: "",
        ipAddress: "",
      },
    });

  const [signIn] = useJudgeAuthorizationMutation({
    update: (client, { data }) => {
      if (data && data.judgeAuthorization) {
        const { judge, jwt, errors } = data.judgeAuthorization;

        if (judge) {
          client.writeQuery<MeQuery>({
            query: MeDocument,
            data: {
              me: judge,
            },
          });

          setCookie(null, process.env.COOKIE, jwt, {
            maxAge: 30 * 24 * 60 * 60,
            path: "/",
          });
        } else {
          errors.forEach((error) => {
            setError("judgeId", {
              type: "manual",
              message: error.detail,
            });
          });
        }
      }
    },
    onCompleted: (data) => {
      if (data && data.judgeAuthorization) {
        const { judge, errors } = data.judgeAuthorization;

        if (judge) {
          router.push("/tournaments");
        } else if (errors) {
          alert("error: " + errors[0].detail);
        }
      }
    },
    onError: (e) => {
      alert(e.message);
    },
  });

  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className="object-cover w-full h-full dark:hidden"
              src="/bg-login.png"
              alt="bg-login"
            />
            <img
              aria-hidden="true"
              className="hidden object-cover w-full h-full dark:block"
              src="/bg-login.png"
              alt="bg-login"
            />
          </div>
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">Login</h1>
              <form
                className="mt-8"
                method="POST"
                onSubmit={handleSubmit(
                  (variables: JudgeAuthorizationMutationVariables) => {
                    signIn({
                      variables: {
                        ...variables,
                        ipAddress: "",
                      },
                    });
                  }
                )}
              >
                <Label>
                  <span>Game Account ID</span>
                  <Input css="" className="mt-1 form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text" name="judgeId" placeholder="569123478" crossOrigin={undefined} ref={register}/>
                </Label>

                <Label className="mt-4">
                  <span>Password</span>
                  <Input css="" className="mt-1 form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="password" name="password" placeholder="***************" crossOrigin={undefined} ref={register}/>
                </Label>
                <Button className="mt-4" block tag={"button"} type="submit">
                  Log in
                </Button>
              </form>

              {/* <hr className="my-8" /> */}

              {/* <p className="mt-4">
                <div
                  className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                >
                  Forgot your password?
                </div>
              </p>
              <p className="mt-1">
                <div
                  className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                >
                  Create account
                </div>
              </p> */}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
